@import '~antd/dist/antd.less';

html {
  font-size: 16px;

  @media screen and (min-width: 600px) and (max-width: 1200px) {
    font-size: 15px;
  }

  @media screen and (min-width: 350px) and (max-width: 600px) {
    font-size: 14px;
  }

  @media screen and (max-width: 350px) {
    font-size: 12px;
  }
}
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.App {
  text-align: center;
}

body {
  font-family: 'Lato';
}

@font-face {
  font-family: 'Lato';
  src: url('./assets/fonts/Lato/Lato-BlackItalic.eot');
  src: url('./assets/fonts/Lato/Lato-BlackItalic.eot?#iefix')
      format('embedded-opentype'),
    url('./assets/fonts/Lato/Lato-BlackItalic.woff2') format('woff2'),
    url('./assets/fonts/Lato/Lato-BlackItalic.woff') format('woff'),
    url('./assets/fonts/Lato/Lato-BlackItalic.ttf') format('truetype'),
    url('./assets/fonts/Lato/Lato-BlackItalic.svg#Lato-BlackItalic')
      format('svg');
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Lato';
  src: url('./assets/fonts/Lato/Lato-Bold.eot');
  src: url('./assets/fonts/Lato/Lato-Bold.eot?#iefix')
      format('embedded-opentype'),
    url('./assets/fonts/Lato/Lato-Bold.woff2') format('woff2'),
    url('./assets/fonts/Lato/Lato-Bold.woff') format('woff'),
    url('./assets/fonts/Lato/Lato-Bold.ttf') format('truetype'),
    url('./assets/fonts/Lato/Lato-Bold.svg#Lato-Bold') format('svg');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Lato';
  src: url('./assets/fonts/Lato/Lato-Black.eot');
  src: url('./assets/fonts/Lato/Lato-Black.eot?#iefix')
      format('embedded-opentype'),
    url('./assets/fonts/Lato/Lato-Black.woff2') format('woff2'),
    url('./assets/fonts/Lato/Lato-Black.woff') format('woff'),
    url('./assets/fonts/Lato/Lato-Black.ttf') format('truetype'),
    url('./assets/fonts/Lato/Lato-Black.svg#Lato-Black') format('svg');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Lato';
  src: url('./assets/fonts/Lato/Lato-BoldItalic.eot');
  src: url('./assets/fonts/Lato/Lato-BoldItalic.eot?#iefix')
      format('embedded-opentype'),
    url('./assets/fonts/Lato/Lato-BoldItalic.woff2') format('woff2'),
    url('./assets/fonts/Lato/Lato-BoldItalic.woff') format('woff'),
    url('./assets/fonts/Lato/Lato-BoldItalic.ttf') format('truetype'),
    url('./assets/fonts/Lato/Lato-BoldItalic.svg#Lato-BoldItalic') format('svg');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Lato';
  src: url('./assets/fonts/Lato/Lato-Italic.eot');
  src: url('./assets/fonts/Lato/Lato-Italic.eot?#iefix')
      format('embedded-opentype'),
    url('./assets/fonts/Lato/Lato-Italic.woff2') format('woff2'),
    url('./assets/fonts/Lato/Lato-Italic.woff') format('woff'),
    url('./assets/fonts/Lato/Lato-Italic.ttf') format('truetype'),
    url('./assets/fonts/Lato/Lato-Italic.svg#Lato-Italic') format('svg');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Lato';
  src: url('./assets/fonts/Lato/Lato-LightItalic.eot');
  src: url('./assets/fonts/Lato/Lato-LightItalic.eot?#iefix')
      format('embedded-opentype'),
    url('./assets/fonts/Lato/Lato-LightItalic.woff2') format('woff2'),
    url('./assets/fonts/Lato/Lato-LightItalic.woff') format('woff'),
    url('./assets/fonts/Lato/Lato-LightItalic.ttf') format('truetype'),
    url('./assets/fonts/Lato/Lato-LightItalic.svg#Lato-LightItalic')
      format('svg');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Lato';
  src: url('./assets/fonts/Lato/Lato-Light.eot');
  src: url('./assets/fonts/Lato/Lato-Light.eot?#iefix')
      format('embedded-opentype'),
    url('./assets/fonts/Lato/Lato-Light.woff2') format('woff2'),
    url('./assets/fonts/Lato/Lato-Light.woff') format('woff'),
    url('./assets/fonts/Lato/Lato-Light.ttf') format('truetype'),
    url('./assets/fonts/Lato/Lato-Light.svg#Lato-Light') format('svg');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Lato';
  src: url('./assets/fonts/Lato/Lato-Regular.eot');
  src: url('./assets/fonts/Lato/Lato-Regular.eot?#iefix')
      format('embedded-opentype'),
    url('./assets/fonts/Lato/Lato-Regular.woff2') format('woff2'),
    url('./assets/fonts/Lato/Lato-Regular.woff') format('woff'),
    url('./assets/fonts/Lato/Lato-Regular.ttf') format('truetype'),
    url('./assets/fonts/Lato/Lato-Regular.svg#Lato-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Lato';
  src: url('./assets/fonts/Lato/Lato-Hairline.eot');
  src: url('./assets/fonts/Lato/Lato-Hairline.eot?#iefix')
      format('embedded-opentype'),
    url('./assets/fonts/Lato/Lato-Hairline.woff2') format('woff2'),
    url('./assets/fonts/Lato/Lato-Hairline.woff') format('woff'),
    url('./assets/fonts/Lato/Lato-Hairline.ttf') format('truetype'),
    url('./assets/fonts/Lato/Lato-Hairline.svg#Lato-Hairline') format('svg');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Lato';
  src: url('./assets/fonts/Lato/Lato-HairlineItalic.eot');
  src: url('./assets/fonts/Lato/Lato-HairlineItalic.eot?#iefix')
      format('embedded-opentype'),
    url('./assets/fonts/Lato/Lato-HairlineItalic.woff2') format('woff2'),
    url('./assets/fonts/Lato/Lato-HairlineItalic.woff') format('woff'),
    url('./assets/fonts/Lato/Lato-HairlineItalic.ttf') format('truetype'),
    url('./assets/fonts/Lato/Lato-HairlineItalic.svg#Lato-HairlineItalic')
      format('svg');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'DM Sans';
  src: url('./assets/fonts/DM_Sans/DMSans-BoldItalic.eot');
  src: url('./assets/fonts/DM_Sans/DMSans-BoldItalic.eot?#iefix') format('embedded-opentype'),
      url('./assets/fonts/DM_Sans/DMSans-BoldItalic.woff2') format('woff2'),
      url('./assets/fonts/DM_Sans/DMSans-BoldItalic.woff') format('woff'),
      url('./assets/fonts/DM_Sans/DMSans-BoldItalic.ttf') format('truetype'),
      url('./assets/fonts/DM_Sans/DMSans-BoldItalic.svg#DMSans-BoldItalic') format('svg');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'DM Sans';
  src: url('./assets/fonts/DM_Sans/DMSans-Italic.eot');
  src: url('./assets/fonts/DM_Sans/DMSans-Italic.eot?#iefix') format('embedded-opentype'),
      url('./assets/fonts/DM_Sans/DMSans-Italic.woff2') format('woff2'),
      url('./assets/fonts/DM_Sans/DMSans-Italic.woff') format('woff'),
      url('./assets/fonts/DM_Sans/DMSans-Italic.ttf') format('truetype'),
      url('./assets/fonts/DM_Sans/DMSans-Italic.svg#DMSans-Italic') format('svg');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'DM Sans';
  src: url('./assets/fonts/DM_Sans/DMSans-Bold.eot');
  src: url('./assets/fonts/DM_Sans/DMSans-Bold.eot?#iefix') format('embedded-opentype'),
      url('./assets/fonts/DM_Sans/DMSans-Bold.woff2') format('woff2'),
      url('./assets/fonts/DM_Sans/DMSans-Bold.woff') format('woff'),
      url('./assets/fonts/DM_Sans/DMSans-Bold.ttf') format('truetype'),
      url('./assets/fonts/DM_Sans/DMSans-Bold.svg#DMSans-Bold') format('svg');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'DM Sans';
  src: url('./assets/fonts/DM_Sans/DMSans-Medium.eot');
  src: url('./assets/fonts/DM_Sans/DMSans-Medium.eot?#iefix') format('embedded-opentype'),
      url('./assets/fonts/DM_Sans/DMSans-Medium.woff2') format('woff2'),
      url('./assets/fonts/DM_Sans/DMSans-Medium.woff') format('woff'),
      url('./assets/fonts/DM_Sans/DMSans-Medium.ttf') format('truetype'),
      url('./assets/fonts/DM_Sans/DMSans-Medium.svg#DMSans-Medium') format('svg');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'DM Sans';
  src: url('./assets/fonts/DM_Sans/DMSans-MediumItalic.eot');
  src: url('./assets/fonts/DM_Sans/DMSans-MediumItalic.eot?#iefix') format('embedded-opentype'),
      url('./assets/fonts/DM_Sans/DMSans-MediumItalic.woff2') format('woff2'),
      url('./assets/fonts/DM_Sans/DMSans-MediumItalic.woff') format('woff'),
      url('./assets/fonts/DM_Sans/DMSans-MediumItalic.ttf') format('truetype'),
      url('./assets/fonts/DM_Sans/DMSans-MediumItalic.svg#DMSans-MediumItalic') format('svg');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'DM Sans';
  src: url('./assets/fonts/DM_Sans/DMSans-Regular.eot');
  src: url('./assets/fonts/DM_Sans/DMSans-Regular.eot?#iefix') format('embedded-opentype'),
      url('./assets/fonts/DM_Sans/DMSans-Regular.woff2') format('woff2'),
      url('./assets/fonts/DM_Sans/DMSans-Regular.woff') format('woff'),
      url('./assets/fonts/DM_Sans/DMSans-Regular.ttf') format('truetype'),
      url('./assets/fonts/DM_Sans/DMSans-Regular.svg#DMSans-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src: url('./assets/fonts/Montserrat/Montserrat-Thin.eot');
  src: url('./assets/fonts/Montserrat/Montserrat-Thin.eot?#iefix') format('embedded-opentype'),
      url('./assets/fonts/Montserrat/Montserrat-Thin.woff2') format('woff2'),
      url('./assets/fonts/Montserrat/Montserrat-Thin.woff') format('woff'),
      url('./assets/fonts/Montserrat/Montserrat-Thin.ttf') format('truetype'),
      url('./assets/fonts/Montserrat/Montserrat-Thin.svg#Montserrat-Thin') format('svg');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src: url('./assets/fonts/Montserrat/Montserrat-ThinItalic.eot');
  src: url('./assets/fonts/Montserrat/Montserrat-ThinItalic.eot?#iefix') format('embedded-opentype'),
      url('./assets/fonts/Montserrat/Montserrat-ThinItalic.woff2') format('woff2'),
      url('./assets/fonts/Montserrat/Montserrat-ThinItalic.woff') format('woff'),
      url('./assets/fonts/Montserrat/Montserrat-ThinItalic.ttf') format('truetype'),
      url('./assets/fonts/Montserrat/Montserrat-ThinItalic.svg#Montserrat-ThinItalic') format('svg');
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src: url('./assets/fonts/Montserrat/Montserrat-Bold.eot');
  src: url('./assets/fonts/Montserrat/Montserrat-Bold.eot?#iefix') format('embedded-opentype'),
      url('./assets/fonts/Montserrat/Montserrat-Bold.woff2') format('woff2'),
      url('./assets/fonts/Montserrat/Montserrat-Bold.woff') format('woff'),
      url('./assets/fonts/Montserrat/Montserrat-Bold.ttf') format('truetype'),
      url('./assets/fonts/Montserrat/Montserrat-Bold.svg#Montserrat-Bold') format('svg');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src: url('./assets/fonts/Montserrat/Montserrat-Black.eot');
  src: url('./assets/fonts/Montserrat/Montserrat-Black.eot?#iefix') format('embedded-opentype'),
      url('./assets/fonts/Montserrat/Montserrat-Black.woff2') format('woff2'),
      url('./assets/fonts/Montserrat/Montserrat-Black.woff') format('woff'),
      url('./assets/fonts/Montserrat/Montserrat-Black.ttf') format('truetype'),
      url('./assets/fonts/Montserrat/Montserrat-Black.svg#Montserrat-Black') format('svg');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src: url('./assets/fonts/Montserrat/Montserrat-BlackItalic.eot');
  src: url('./assets/fonts/Montserrat/Montserrat-BlackItalic.eot?#iefix') format('embedded-opentype'),
      url('./assets/fonts/Montserrat/Montserrat-BlackItalic.woff2') format('woff2'),
      url('./assets/fonts/Montserrat/Montserrat-BlackItalic.woff') format('woff'),
      url('./assets/fonts/Montserrat/Montserrat-BlackItalic.ttf') format('truetype'),
      url('./assets/fonts/Montserrat/Montserrat-BlackItalic.svg#Montserrat-BlackItalic') format('svg');
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src: url('./assets/fonts/Montserrat/Montserrat-ExtraLight.eot');
  src: url('./assets/fonts/Montserrat/Montserrat-ExtraLight.eot?#iefix') format('embedded-opentype'),
      url('./assets/fonts/Montserrat/Montserrat-ExtraLight.woff2') format('woff2'),
      url('./assets/fonts/Montserrat/Montserrat-ExtraLight.woff') format('woff'),
      url('./assets/fonts/Montserrat/Montserrat-ExtraLight.ttf') format('truetype'),
      url('./assets/fonts/Montserrat/Montserrat-ExtraLight.svg#Montserrat-ExtraLight') format('svg');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src: url('./assets/fonts/Montserrat/Montserrat-BoldItalic.eot');
  src: url('./assets/fonts/Montserrat/Montserrat-BoldItalic.eot?#iefix') format('embedded-opentype'),
      url('./assets/fonts/Montserrat/Montserrat-BoldItalic.woff2') format('woff2'),
      url('./assets/fonts/Montserrat/Montserrat-BoldItalic.woff') format('woff'),
      url('./assets/fonts/Montserrat/Montserrat-BoldItalic.ttf') format('truetype'),
      url('./assets/fonts/Montserrat/Montserrat-BoldItalic.svg#Montserrat-BoldItalic') format('svg');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src: url('./assets/fonts/Montserrat/Montserrat-ExtraBold.eot');
  src: url('./assets/fonts/Montserrat/Montserrat-ExtraBold.eot?#iefix') format('embedded-opentype'),
      url('./assets/fonts/Montserrat/Montserrat-ExtraBold.woff2') format('woff2'),
      url('./assets/fonts/Montserrat/Montserrat-ExtraBold.woff') format('woff'),
      url('./assets/fonts/Montserrat/Montserrat-ExtraBold.ttf') format('truetype'),
      url('./assets/fonts/Montserrat/Montserrat-ExtraBold.svg#Montserrat-ExtraBold') format('svg');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src: url('./assets/fonts/Montserrat/Montserrat-ExtraLightItalic.eot');
  src: url('./assets/fonts/Montserrat/Montserrat-ExtraLightItalic.eot?#iefix') format('embedded-opentype'),
      url('./assets/fonts/Montserrat/Montserrat-ExtraLightItalic.woff2') format('woff2'),
      url('./assets/fonts/Montserrat/Montserrat-ExtraLightItalic.woff') format('woff'),
      url('./assets/fonts/Montserrat/Montserrat-ExtraLightItalic.ttf') format('truetype'),
      url('./assets/fonts/Montserrat/Montserrat-ExtraLightItalic.svg#Montserrat-ExtraLightItalic') format('svg');
  font-weight: 200;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src: url('./assets/fonts/Montserrat/Montserrat-ExtraBoldItalic.eot');
  src: url('./assets/fonts/Montserrat/Montserrat-ExtraBoldItalic.eot?#iefix') format('embedded-opentype'),
      url('./assets/fonts/Montserrat/Montserrat-ExtraBoldItalic.woff2') format('woff2'),
      url('./assets/fonts/Montserrat/Montserrat-ExtraBoldItalic.woff') format('woff'),
      url('./assets/fonts/Montserrat/Montserrat-ExtraBoldItalic.ttf') format('truetype'),
      url('./assets/fonts/Montserrat/Montserrat-ExtraBoldItalic.svg#Montserrat-ExtraBoldItalic') format('svg');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src: url('./assets/fonts/Montserrat/Montserrat-MediumItalic.eot');
  src: url('./assets/fonts/Montserrat/Montserrat-MediumItalic.eot?#iefix') format('embedded-opentype'),
      url('./assets/fonts/Montserrat/Montserrat-MediumItalic.woff2') format('woff2'),
      url('./assets/fonts/Montserrat/Montserrat-MediumItalic.woff') format('woff'),
      url('./assets/fonts/Montserrat/Montserrat-MediumItalic.ttf') format('truetype'),
      url('./assets/fonts/Montserrat/Montserrat-MediumItalic.svg#Montserrat-MediumItalic') format('svg');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src: url('./assets/fonts/Montserrat/Montserrat-Italic.eot');
  src: url('./assets/fonts/Montserrat/Montserrat-Italic.eot?#iefix') format('embedded-opentype'),
      url('./assets/fonts/Montserrat/Montserrat-Italic.woff2') format('woff2'),
      url('./assets/fonts/Montserrat/Montserrat-Italic.woff') format('woff'),
      url('./assets/fonts/Montserrat/Montserrat-Italic.ttf') format('truetype'),
      url('./assets/fonts/Montserrat/Montserrat-Italic.svg#Montserrat-Italic') format('svg');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src: url('./assets/fonts/Montserrat/Montserrat-SemiBold.eot');
  src: url('./assets/fonts/Montserrat/Montserrat-SemiBold.eot?#iefix') format('embedded-opentype'),
      url('./assets/fonts/Montserrat/Montserrat-SemiBold.woff2') format('woff2'),
      url('./assets/fonts/Montserrat/Montserrat-SemiBold.woff') format('woff'),
      url('./assets/fonts/Montserrat/Montserrat-SemiBold.ttf') format('truetype'),
      url('./assets/fonts/Montserrat/Montserrat-SemiBold.svg#Montserrat-SemiBold') format('svg');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src: url('./assets/fonts/Montserrat/Montserrat-LightItalic.eot');
  src: url('./assets/fonts/Montserrat/Montserrat-LightItalic.eot?#iefix') format('embedded-opentype'),
      url('./assets/fonts/Montserrat/Montserrat-LightItalic.woff2') format('woff2'),
      url('./assets/fonts/Montserrat/Montserrat-LightItalic.woff') format('woff'),
      url('./assets/fonts/Montserrat/Montserrat-LightItalic.ttf') format('truetype'),
      url('./assets/fonts/Montserrat/Montserrat-LightItalic.svg#Montserrat-LightItalic') format('svg');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src: url('./assets/fonts/Montserrat/Montserrat-Regular.eot');
  src: url('./assets/fonts/Montserrat/Montserrat-Regular.eot?#iefix') format('embedded-opentype'),
      url('./assets/fonts/Montserrat/Montserrat-Regular.woff2') format('woff2'),
      url('./assets/fonts/Montserrat/Montserrat-Regular.woff') format('woff'),
      url('./assets/fonts/Montserrat/Montserrat-Regular.ttf') format('truetype'),
      url('./assets/fonts/Montserrat/Montserrat-Regular.svg#Montserrat-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src: url('./assets/fonts/Montserrat/Montserrat-Light.eot');
  src: url('./assets/fonts/Montserrat/Montserrat-Light.eot?#iefix') format('embedded-opentype'),
      url('./assets/fonts/Montserrat/Montserrat-Light.woff2') format('woff2'),
      url('./assets/fonts/Montserrat/Montserrat-Light.woff') format('woff'),
      url('./assets/fonts/Montserrat/Montserrat-Light.ttf') format('truetype'),
      url('./assets/fonts/Montserrat/Montserrat-Light.svg#Montserrat-Light') format('svg');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src: url('./assets/fonts/Montserrat/Montserrat-Medium.eot');
  src: url('./assets/fonts/Montserrat/Montserrat-Medium.eot?#iefix') format('embedded-opentype'),
      url('./assets/fonts/Montserrat/Montserrat-Medium.woff2') format('woff2'),
      url('./assets/fonts/Montserrat/Montserrat-Medium.woff') format('woff'),
      url('./assets/fonts/Montserrat/Montserrat-Medium.ttf') format('truetype'),
      url('./assets/fonts/Montserrat/Montserrat-Medium.svg#Montserrat-Medium') format('svg');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src: url('./assets/fonts/Montserrat/Montserrat-ThinItalic_1.eot');
  src: url('./assets/fonts/Montserrat/Montserrat-ThinItalic_1.eot?#iefix') format('embedded-opentype'),
      url('./assets/fonts/Montserrat/Montserrat-ThinItalic_1.woff2') format('woff2'),
      url('./assets/fonts/Montserrat/Montserrat-ThinItalic_1.woff') format('woff'),
      url('./assets/fonts/Montserrat/Montserrat-ThinItalic_1.ttf') format('truetype'),
      url('./assets/fonts/Montserrat/Montserrat-ThinItalic_1.svg#Montserrat-ThinItalic') format('svg');
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src: url('./assets/fonts/Montserrat/Montserrat-Thin_1.eot');
  src: url('./assets/fonts/Montserrat/Montserrat-Thin_1.eot?#iefix') format('embedded-opentype'),
      url('./assets/fonts/Montserrat/Montserrat-Thin_1.woff2') format('woff2'),
      url('./assets/fonts/Montserrat/Montserrat-Thin_1.woff') format('woff'),
      url('./assets/fonts/Montserrat/Montserrat-Thin_1.ttf') format('truetype'),
      url('./assets/fonts/Montserrat/Montserrat-Thin_1.svg#Montserrat-Thin') format('svg');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src: url('./assets/fonts/Montserrat/Montserrat-SemiBoldItalic.eot');
  src: url('./assets/fonts/Montserrat/Montserrat-SemiBoldItalic.eot?#iefix') format('embedded-opentype'),
      url('./assets/fonts/Montserrat/Montserrat-SemiBoldItalic.woff2') format('woff2'),
      url('./assets/fonts/Montserrat/Montserrat-SemiBoldItalic.woff') format('woff'),
      url('./assets/fonts/Montserrat/Montserrat-SemiBoldItalic.ttf') format('truetype'),
      url('./assets/fonts/Montserrat/Montserrat-SemiBoldItalic.svg#Montserrat-SemiBoldItalic') format('svg');
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}


